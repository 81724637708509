import type { CoordinateTransformResult } from "@maptiler/client"
import type { GeoPoint } from "./types"

export const enum CoordinateSystem {
  WGS86 = 4326,
  RD = 28992
}

export const useGeoStore = defineStore("geo", () => {
  const transform = (sourceCrs: CoordinateSystem, targetCrs: CoordinateSystem, position: Array<number>) => {
    return useSamApi<CoordinateTransformResult>("/geo/transform", {
      method: "POST",
      body: JSON.stringify({ sourceCrs, targetCrs, position })
    })
  }

  const convertWGSToRD = async (wgsGeoPoint: GeoPoint): Promise<GeoPoint> => {
    const { data: transformResult } = await transform(4326, 28992, [wgsGeoPoint.coordinates[0], wgsGeoPoint.coordinates[1]])

    const { x, y } = transformResult.results?.[0]
    if (!x || !y) { throw new Error("Invalid coordinates") }

    return {
      type: "Point",
      coordinates: [
        parseFloat(y.toFixed(2)),
        parseFloat(x.toFixed(2)),
        wgsGeoPoint.coordinates[2]
      ]
    }
  }

  const convertRDToWGS = async (rdGeoPoint: GeoPoint): Promise<GeoPoint> => {
    const { data: transformResult } = await transform(28992, 4326, [rdGeoPoint.coordinates[1], rdGeoPoint.coordinates[0]])

    const { x, y } = transformResult.results?.[0]
    if (!x || !y) { throw new Error("Invalid coordinates") }

    return {
      type: "Point",
      coordinates: [
        parseFloat(x.toFixed(7)),
        parseFloat(y.toFixed(7)),
        rdGeoPoint.coordinates[2]
      ]
    }
  }

  const transformOrganizationCoordinates = async (organizationId: string, sourceCrs: CoordinateSystem) => {
    return useSamApi<CoordinateTransformResult>(`/geo/transform/organization/${organizationId}`, {
      method: "POST",
      body: JSON.stringify({ sourceCrs })
    })
  }

  return {
    transform,
    transformOrganizationCoordinates,
    convertWGSToRD,
    convertRDToWGS
  }
})